.banner {
          position: relative;
          background: #fff url('../../Assests/i1.jpg');
          /* padding-top: 120px; */
          padding-bottom: 150px;
          text-align: center;
          /* height: 600000em; */
          background-repeat: no-repeat;
          background-size: cover;
      }
      
      .banner:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
          z-index: 1;
      }
      
      .banner .container {
          position: relative;
          margin-top: 90px;
          z-index: 2; /* Keeps the content above the overlay */
      }
      
      .banner h2, .banner h3 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1rem;
          line-height: 1.2em;
          font-weight: 800;
          color: #eee;
          text-transform: capitalize;
      }
      
      .banner h3 {
          margin-bottom: 25px;
          font-size: 20px;
          line-height: 25px;
          color: #ddd;
          text-transform: uppercase;
          letter-spacing: 0.055em;
      }
      
      /* Button styles */
      .btn-animated {
          padding: 10px 30px;
          font-size: 16px;
          color: #000000;
          background-color: #96dfe2;
          border: none;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
          text-decoration: none;
          display: inline-block;
          transition: transform 0.3s ease, background-color 0.3s ease;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      }
      
      .btn-animated:hover {
          background-color: #f5e12d;
          transform: scale(1.1);
          box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
      }
      
      .btn-animated:active {
          transform: scale(0.95);
      }
      
      /* Media Queries for Responsiveness */
      @media (max-width: 768px) {
          .banner {
              padding-top: 100px;
              padding-bottom: 100px;
              height: auto;
          }
      
          .banner h2 {
              font-size: 1rem;
          }
      
          .banner h3 {
              font-size: 16px;
          }
      
          .btn-animated {
              padding: 8px 20px;
              font-size: 14px;
          }
      }
      
      @media (max-width: 480px) {
          .banner {
              padding-top: 80px;
              padding-bottom: 80px;
              height: auto;
          }
      
          .banner h2 {
              font-size: 2.5rem;
          }
      
          .banner h3 {
              font-size: 14px;
          }
      
          .btn-animated {
              padding: 6px 15px;
              font-size: 12px;
          }
      }
      
      .gradient-line {
          height: 5px; /* Adjust height if needed */
          background: linear-gradient(to right, rgb(255, 243, 14), rgb(141, 235, 230), rgb(0, 0, 0), rgb(185, 185, 185));
          width: 100%; /* Make it full width */
        }
        /* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .banner {
        padding-top: 100px;
        padding-bottom: 100px;
        height: auto;
    }

    .banner h2 {
        font-size: 2rem; /* Adjusted font size for tablet screens */
        line-height: 40px; /* Adjusted line height */
    }

    .banner h3 {
        font-size: 1.2rem; /* Adjusted font size for tablet screens */
        line-height: 25px; /* Adjusted line height */
    }

    .btn-animated {
        padding: 8px 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .banner {
        padding-top: 80px;
        padding-bottom: 80px;
        height: auto;
    }

    .banner h2 {
        font-size: 1rem; /* Smaller font size for mobile */
        line-height: 30px; /* Adjusted line height */
    }

    .banner h3 {
        font-size: 1rem; /* Smaller font size for mobile */
        line-height: 20px; /* Adjusted line height */
    }

    .btn-animated {
        padding: 6px 15px;
        font-size: 12px;
    }
}


/*   
  #banner {
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
  }
  
  #banner #banner-images {
      height: 100%;
      position: relative;
      object-fit: cover;
      z-index: -10;
      filter: brightness(0.7) grayscale(0.6);
      animation: banner-change 15s infinite ease-in-out;
      display: flex;
      flex-direction: row;
  }
  #banner #banner-images img {
      width: 100vw;
      object-fit: cover;
  }
  
  @keyframes banner-change{
      0% {
          right: -200vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      20.5% {
          right: -200vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      22.5% {
          right: -200vw;
          filter: brightness(0) grayscale(0.6);
      }
      23% {
          right: -100vw;
          filter: brightness(0) grayscale(0.6);
      }
      25% {
          right: -100vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      45.5% {
          right: -100vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      47.5% {
          right: -100vw;
          filter: brightness(0) grayscale(0.6);
      }
      48% {
          right: 0vw;
          filter: brightness(0) grayscale(0.6);
      }
      50% {
          right: 0vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      70.5% {
          right: 0vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      72.5% {
          right: 0vw;
          filter: brightness(0) grayscale(0.6);
      }
      73% {
          right: 100vw;
          filter: brightness(0) grayscale(0.6);
      }
      75% {
          right: 100vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      95.5% {
          right: 100vw;
          filter: brightness(0.7) grayscale(0.6);
      }
      97.5% {
          right: 100vw;
          filter: brightness(0) grayscale(0.6);
      }
      98% {
          right: 200vw;
          filter: brightness(0) grayscale(0.6);
      }
      100% {
          right: 200vw;
          filter: brightness(0.7) grayscale(0.6);
      }
  }
  
  #banner h1 {
      position: absolute;
      font-family: sans-serif;
      font-size: 40px;
      letter-spacing: 8px;
      color: rgb(230,255,255);
      text-shadow: 2px 2px 8px rgb(206, 206, 206);
      animation: color-cycle 5s infinite ease-in-out;
      color: rgb(233, 233, 233);
      text-transform: uppercase;
      margin-top: -14px;
      text-align: center;
      width: 90%;
  }

   */
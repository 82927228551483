.scroll-buttons {
          position: fixed;
          right: 10px;
          top: 50%;
          display: flex;
          flex-direction: column;
          transform: translateY(-50%);
          z-index: 999;
        }
        
        .scroll-button {
          padding: 10px;
          margin: 5px 0;
          background-color: #000000;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        
        .scroll-button:hover {
          background-color: #f0e760;
          color: #000000;
        }
        
.testimonial-slider-container {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          text-align: center;
          position: relative;
        }
        
        .testimonial-heading {
          font-size: 2rem;
          margin-bottom: 30px;
          font-weight: bold;
        }
        
        .testimonial-card {
          background-color: #333;
          color: white;
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        
        .testimonial-image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 15px;
        }
        
        .client-name {
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 5px;
          color: orange;
        }
        
        .client-profession {
          font-size: 1rem;
          color: #bbb;
          margin-bottom: 10px;
        }
        
        .testimonial-text {
          font-size: 0.9rem;
          color: #ccc;
          margin: 0 10px;
        }
        
        /* Navigation Button Styles */
        .swiper-button-prev, .swiper-button-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          cursor: pointer;
          background-color: #36b7d1;
          color: rgb(223, 200, 22);
          padding: 10px 20px;
          border-radius: 5px;
          font-weight: bold;
          transition: background-color 0.3s ease;
        }
        
        .swiper-button-prev:hover, .swiper-button-next:hover {
          background-color: orange;
        }
        
        .swiper-button-prev {
          left: 10px;
        }
        
        .swiper-button-next {
          right: 10px;
        }
        
        @media (max-width: 768px) {
          .testimonial-card {
            padding: 15px;
          }
          .client-name {
            font-size: 1rem;
          }
          .client-profession,
          .testimonial-text {
            font-size: 0.85rem;
          }
        
          .swiper-button-prev, .swiper-button-next {
            padding: 8px 15px;
            font-size: 0.9rem;
          }
        }
        
        @media (max-width: 480px) {
          .testimonial-heading {
            font-size: 1.5rem;
          }
          .testimonial-card {
            padding: 10px;
          }
          .client-name {
            font-size: 0.9rem;
          }
          .client-profession,
          .testimonial-text {
            font-size: 0.75rem;
          }
        
          .swiper-button-prev, .swiper-button-next {
            padding: 6px 10px;
            font-size: 0.75rem;
          }
        }
        
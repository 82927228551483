
/* Container for the banner */
.banner-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  /* Styling for the image */
  .banner-image {
    width: 100%;
    height:350em;
    animation: fadeIn 2s ease-in-out;
  }
  
  /* Overlay styling */
  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1; /* Place it above the image */
  }
  
  /* Text content in the banner */
  .banner-content {
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate(-10%, -30%);
    color: #000000;
    text-align: left;
    z-index: 2; /* Place it above the overlay */
    animation: slideInLeft 2s ease;
  }
  
  /* Title style */
  .title {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    font-family: "Abril Fatface", serif;
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  }
  
  /* Subtitle style */
  .subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: sans-serif;
    color: #ddd;
  }
  
  /* Keyframes for fade-in effect */
  @keyframes fadeIn {
    from {
      opacity: 0
    }}  


.about {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.about .section-header {
    margin-bottom: 30px;
    margin-left: 0;
}

.about .about-img {
    position: relative;
    height: 100%;
    margin: 60px 0 0 60px;
}

.about .about-img img {
    position: relative;
    width: 100%;
    height: 30em;
    object-fit: cover;
    z-index: 1;
}

.about .about-img::before {
    position: absolute;
    content: "";
    top: -60px;
    right: 60px;
    bottom: 60px;
    left: -60px;
    background: #7edcca;
    z-index: 0;
}

.about .about-img::after {
    position: absolute;
    content: "";
    top: -30px;
    right: 30px;
    bottom: 30px;
    left: -30px;
    background: transparent;
    border: 1px solid #f8d15c;
    z-index: 2;
}

.about .about-text p {
    font-size: 16px;
}

.about .about-text a.btn {
    position: relative;
    margin-top: 15px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #1d2434;
    border: 2px solid #1d2434;
    border-radius: 0;
    background: none;
    transition: .3s;
}

.about .about-text a.btn:hover {
    color: #D5B981;
    background: #1d2434;
}

@media (max-width: 767.98px) {
    .about .about-img {
        margin-bottom: 30px;
        height: auto;
    }
}

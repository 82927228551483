.slider-container {
          text-align: center;
          align-items: center;
          justify-content: center;
          margin: 50px auto;
          width: 100%;
          border:2px solid #ffffff;
}

.logo-slide {
          padding: 10px;
}

.logo-image {
          width: 20em;
          height: 19em;
          object-fit: contain;
          /* top: 50%; */
          /* margin-right: 20px; */
          
      
}
@media (max-width: 600px) {
          .slider-container {
                    margin-top: -4em;
                   
          }
}


/* about */

/* Main section styles */
.blog_section,
.blog_sections {
          width: 100%;
          background-color: rgb(255, 255, 255);
          padding: 30px 0;
          /* Add padding for better spacing */
          /* margin-top: 50px; */
}

.blog_sections {
          background-color: #84d5c6;
}

/* Image styles */
.blog_img,
.blog_imgs {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          /* Add some spacing below the images */
}

/* Headings */
.blog_taital,
.blog_taitals {
          width: 100%;
          font-size: 40px;
          color: #0d836d;
          font-weight: bold;
          padding-top: 20px;
          /* font-family: 'Roboto', sans-serif; */
}

.blog_taitals {
          color: #edd626;
}

/* Paragraphs */
.blog_text,
.blog_texts {
          width: 100%;
          font-size: 24px;
          color: #141414;
          margin: 0px;
          padding-top:90px;
          font-family: "Trirong", serif;
          /* font-family: 'Roboto', sans-serif; */
}

/* Media Queries for Responsiveness */

/* Large screens (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 32px;
          }

          .blog_text,
          .blog_texts {
                    font-size: 18px;
          }
}

/* Medium screens (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 26px;
          }

          .blog_text,
          .blog_texts {
                    font-size: 18px;
          }
}

/* Small screens (phones) */
@media only screen and (max-width: 767px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 22px;
                    text-align: center;
                    /* Center the heading on small screens */
          }

          .blog_text,
          .blog_texts {
                    font-size: 16px;
                    text-align: center;
                    /* Center the text on small screens */
          }

          .blog_img,
          .blog_imgs {
                    margin-bottom: 15px;
                    /* Add some spacing below images */
          }
}

/* Extra small screens (very small phones) */
@media only screen and (max-width: 575px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 20px;
                    text-align: center;
          }

          .blog_text,
          .blog_texts {
                    font-size: 14px;
                    text-align: center;
          }
}

/* Main section styles */
.blog_section,
.blog_sections {
          width: 100%;
          background-color: rgb(234, 234, 234);
          padding: 40px 0;
          /* Add padding for better spacing */
}

.blog_sections {
          background-color: #efefef;
}

/* Responsive Image Styles */
.blog_img img,
.blog_imgs img {
          width: 100%;
          height: auto;
          display: block;
          object-fit: cover;
          /* Ensures the image fills the space without distortion */
          max-width: 100%;
          /* Prevents the image from exceeding the container width */
}

/* Headings */
.blog_taital,
.blog_taitals {
          width: 100%;
          font-size: 40px;
          color: #6bd4c1;
          font-weight: bold;
          padding-top: 20px;
          font-family: 'Roboto', sans-serif;
}

.blog_taitals {
          color: #edd626;
}

/* Paragraphs */
.blog_text,
.blog_texts {
          width: 100%;
          font-size: 21px;
          color: #141414;
          margin: 0px;
          padding-top: 20px;
          font-family: 'Roboto', sans-serif;
}

/* Media Queries for Responsiveness */

/* Large screens (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 32px;
          }

          .blog_text,
          .blog_texts {
                    font-size: 18px;
          }
}

/* Medium screens (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 26px;
          }

          .blog_text,
          .blog_texts {
                    font-size: 18px;
          }
}

/* Small screens (phones) */
@media only screen and (max-width: 767px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 22px;
                    text-align: center;
                    /* Center the heading on small screens */
          }

          .blog_text,
          .blog_texts {
                    font-size: 16px;
                    text-align: center;
                    /* Center the text on small screens */
          }

          .blog_img,
          .blog_imgs {
                    margin-bottom: 15px;
                    /* Add some spacing below images */
          }
}

/* Extra small screens (very small phones) */
@media only screen and (max-width: 575px) {

          .blog_taital,
          .blog_taitals {
                    font-size: 20px;
                    text-align: center;
          }

          .blog_text,
          .blog_texts {
                    font-size: 14px;
                    text-align: center;
          }
}

/* services */

.service-item {
          transition: .5s;
          border: 4px solid #f6e211;
          height: 23em;
}

.service-item:hover {
          box-shadow: 0 0 30px #606060;
}

.service-item i {
          width: 75px;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #6cdac6;
          background: #f5f5f5;
          color: #e2d32c;
          transition: .5s;
}

.service-item:hover i {
          background: #6cdac6;
          color: #000000;
}

/*  */

.bg-registration {
          background: #6cdac6;
          background-size: cover;
}

/* 
---------------------------------------------
reservation
---------------------------------------------

*/
#reservation {
          margin-top: 10px;
          padding: 120px 0px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative; /* Ensures the overlay is positioned relative to the section */
        }
        
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
          z-index: 1; /* Ensure it sits behind the content but above the background image */
        }
        
        .container, 
        .left-text-content, 
        .contact-form {
          position: relative;
          z-index: 2; /* Ensure the content appears above the overlay */
        }
#reservation .container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 50vh; /* Full viewport height */
        }
        
        #reservation {
          margin-top: 120px;
          padding: 120px 0px;
          background-image: url(../../Assests/wind1.jpg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        
        #contact {
          margin-left: 10px;
          padding: 45px 10px;
          background-color: #fff;
          border-radius: 5px;
          border: 8px solid #6cdac6;
          align-items: center;
          justify-content: center;
          font-family: "Trirong", serif;
        }
        
        .contact-form input,
        .contact-form textarea,
        .contact-form select {
          color: #2a2a2a;
          font-size: 19px;
          font-weight: 800;
          border: 1px solid #ddd;
          background-color: #fff;
          font-family: "Trirong", serif;
          width: 100%;
          height: 60px;
          border-radius: 5px;
          padding: 10px 20px;
          margin-bottom: 15px;
        }
        
        .contact-form button {
          font-size: 13px;
          background-color: #6cdac6;
          color: #000;
          padding: 12px 25px;
          width: 100%;
          border-radius: 3px;
          transition: all 0.3s;
        }
        
        .contact-form button:hover {
          opacity: 0.9;
        }
        
        @media (max-width: 436px) {
          #contact {
            max-width: 90vw; /* Increase width on small screens */
          }
        }
        
        @media (min-width: 768px) {
          #contact {
            max-width: 900px;
          }
        }
        
        @media (min-width: 1200px) {
          #contact {
            max-width: 1100px;
          }
        }
    


        .gradient-line {
          height: 5px; /* Adjust height if needed */
          background: linear-gradient(to right, rgb(255, 243, 14), rgb(141, 235, 230), rgb(0, 0, 0), rgb(185, 185, 185));
          width: 100%; /* Make it full width */
        }


        /* CustomAlert.css */

.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-alert-box {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.custom-alert-box h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #4cafa2;
}

.custom-alert-box p {
  font-size: 1rem;
  margin: 10px 0;
}

.alert-close-button {
  background-color: #ebff57;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.alert-close-button:hover {
  transform: scale(1.1);
  background-color:#ebff57;
}


/*  */

/** services section **/

.services .titlepage  {
  text-align: center;
 
}
.services .titlepage h2{
  text-align: center;
  font-size: 2.1rem;
  font-weight: 800;
  color: #59c3b0;
}
.services .titlepage p{
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
  color: #838383;
}
.services {
  background-color: #fff;
  padding: 10px 0 0px 0;
}

.services .services_box {
  text-align: center;
  background-color: #f6f6f6;
  padding: 80px 0;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: ease-in all 0.5s;
}

.services .services_box i {
  background-color: #b6e8e2;
  height: 200px;
  width: 200px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.services .services_box h3 {
  color: #000000;
  font-size: 25px;
  line-height: 20px;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 40px !important;
  transition: ease-in all 0.5s;
  margin: auto;
}

.right_irro i {
  width: 10em !important;
  height: 10em !important;
  margin-top: 40px !important;
  border-radius: 0 !important;
  color: #fff;
  opacity: 0;
  transition: ease-in all 0.5s;
}

#serv_hover:hover.services_box {
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(57, 56, 56, 0.2);
  background-color: #6cc2c2;
  border-top: #ffea30 solid 10px;
  transition: ease-in all 0.5s;
}

#serv_hover:hover.services_box h3 {
  color: #ffea30;
  transition: ease-in all 0.5s;
}

#serv_hover:hover .right_irro i {
  opacity: 1;
  transition: ease-in all 0.5s;
  background: #bbf2e8;
}

.margin_bottom1 {
  margin-bottom: 60px;
}

.services .read_more {
  margin: 0 auto;
  display: block;
  margin-top: 50px;
  background-color: transparent;
  border: #48ca95 solid 2px;
  color: #48ca95 !important;
  max-width: 232px;
  padding: 15px 0;
}

.services .read_more:hover {
  background: #48ca95;
  color: #000 !important;
}



/** end services section **/
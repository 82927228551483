

/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
  margin-top: 45px;
  padding-top: 90px;
  background: #ffffff;
  box-shadow: 10px 5px 5px black;
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-newsletter {
  position: relative;
  margin-bottom: 45px;
  color: #000000;
}

.footer .footer-contact h2,
.footer .footer-link h2,
.footer .footer-newsletter h2{
  position: relative;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
  color: #6ac5bf;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #363636;
  transition: .3s;
}

.footer .footer-link a::before {
  position: relative;
  /* content: "\f105"; */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 30px;
}

.footer .footer-link a:hover {
  color: #000000;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
}

.footer .footer-social a i {
  margin-right: 15px;
  font-size: 18px;
  color: #D5B981;
}

.footer .footer-social a:last-child i {
  margin: 0;
}

.footer .footer-social a:hover i {
  color: #999999;
}

.footer .footer-newsletter .form {
  position: relative;
  width: 100%;
}

.footer .footer-newsletter input {
  height: 60px;
  border: 2px solid #1d2434;
  border-radius: 0;
}

.footer .footer-newsletter .btn {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 44px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #1d2434;
  background: none;
  border-radius: 0;
  border: 2px solid #1d2434;
  transition: .3s;
}

.footer .footer-newsletter .btn:hover {
  color: #D5B981;
  background: #1d2434;
}

.footer .copyright {
  padding: 0 30px;
}

.footer .copyright .row {
  background: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer .copyright p {
  margin: 0;
  color: #454545;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #1d2434;
  font-weight: 700;
}

.footer .copyright p a:hover {
  color: #D5B981;
}
.footer-social {
  display: flex;
  gap: 15px; /* Adjust the gap between icons as needed */
}

.footer-social a {
  /* height: 2rem; */
  color: #2a2a2a; /* Default color for icons */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}

.footer-social a:hover {
  color: #66beb8; /* Change to any color on hover */
  transform: scale(1.2); /* Slightly enlarge the icon on hover */
}

@media (max-width: 767.98px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
      margin: 5px 0;
      text-align: center;
  }
}

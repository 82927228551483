
/* Container for the banner */
.banner-container {
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        
        /* Styling for the image */
        .banner-image {
          width: 100%;
          height:20em;
          animation: fadeIn 2s ease-in-out;
        }
        
        /* Overlay styling */
        .banner-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
          z-index: 1; /* Place it above the image */
        }
        
        /* Text content in the banner */
        .banner-content {
          position: absolute;
          top: 30%;
          left: 10%;
          transform: translate(-10%, -30%);
          color: #000000;
          text-align: left;
          z-index: 2; /* Place it above the overlay */
          animation: slideInLeft 2s ease;
        }
       
        /* Title style */
        .tittle {
          font-size: 3rem;
          font-weight: bold;
          color: #fff;
          font-family: "Abril Fatface", serif;
          /* font-family: "Sofia", sans-serif; */
          /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
        }
        
        /* Subtitle style */
        .subtitle {
          font-size: 1.3rem;
          font-weight: 500;
          /* font-family: ; */
          font-family: "Trirong", serif;
          line-height: 36px;
          color: #ddd;
        }
        
        /* Keyframes for fade-in effect */
        @keyframes fadeIn {
          from {
            opacity: 0
          }}  
      
      
          .contact-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 20px;
                    gap: 20px;
                  }
                  
                  .form-section, .map-section {
                    flex: 1;
                    min-width: 300px;
                    margin: 10px;
                    background-color: #ebebeb;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                  }
                  
                  h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                    color: #2a2a2a;
                  }
                  
                  .form-group {
                    margin-bottom: 15px;
                  }
                  
                  label {
                    display: block;
                    margin-bottom: 5px;
                    font-weight: bold;
                    color: #56bdb1;
                    font-size: 18px;
                  }
                  
                  .input-field {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 16px;
                    transition: all 0.3s ease-in-out;
                  }
                  
                  .message-field {
                    height: 100px;
                  }
                  
                  .input-field:focus {
                    border-color: #59b8b8;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
                  }
                  
                  .submit-button {
                    width: 100%;
                    padding: 10px;
                    background-color: #59b8b8;
                    color: rgb(0, 0, 0);
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: background-color 0.3s ease-in-out;
                  }
                  
                  .submit-button:hover {
                    background-color: #f9ff4b;
                  }
                  
                  .map-section {
                    flex: 1;
                  }
                  
                  @media (max-width: 768px) {
                    .form-section, .map-section {
                      flex: 100%;
                    }
                  }
             /* CustomAlert.css */

.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-alert-box {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.custom-alert-box h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #000000;
}

.custom-alert-box p {
  font-size: 1rem;
  margin: 10px 0;
}

.alert-close-button {
  background-color: #63c4b4;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.alert-close-button:hover {
  transform: scale(1.1);
  background-color: #58c0b4;
}
